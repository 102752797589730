import Chart from 'chart.js'

function createLineChartType1(id, data) {
      let dataset_pactual = [];
      let labels_pactual = [];
      if (data[0].dataset != []) {
        data[0].dataset.forEach(function(results) {
          dataset_pactual.push(results.count);
          labels_pactual.push(results._id);
        });
      }
      let dataset_panterior = [];
      let labels_panterior = [];
      if (data[1].dataset != []) {
        data[1].dataset.forEach(function(results) {
          dataset_panterior.push(results.count);
          labels_panterior.push(results._id);
        });
      }
      let dataset_aanterior = [];
      let labels_aanterior = [];
      if (data[2].dataset != []) {
        data[2].dataset.forEach(function(results) {
          dataset_aanterior.push(results.count);
          labels_aanterior.push(results._id);
        });
      }

      new Chart(id, {
        type: 'line',
        data: {
          labels: labels_pactual,
          datasets: [
            {
              label: 'Período actual',
              data: dataset_pactual,
              fill: false,
              borderColor: 'rgb(255, 0, 0)',
              borderWidth: 1,
              hidden: false,
            },
            {
              label: 'Período anterior',
              data: dataset_panterior,
              fill: false,
              borderColor: 'rgb(255, 0, 255)',
              borderWidth: 1,
              hidden: false,
            },
            {
              label: 'Año anterior',
              data: dataset_aanterior,
              fill: false,
              borderColor: 'rgb(0, 0, 255)',
              borderWidth: 1,
              hidden: false,
            },
          ],
        },
        options: {
          title: {
            display: false,
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 15,
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  color: 'rgba(0, 0, 0, 0)',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  color: 'rgba(0, 0, 0, 0)',
                },
              },
            ],
          },
        },
      });
}


function createLineChartType2(id, data) {
      let dataset_pacdesktop = [];
      let dataset_pacmobile = [];
      let labels_pactual = [];
      if (data[0].dataset) {
        data[0].dataset.forEach(function(results) {
          dataset_pacdesktop.push(results.desktop);
          dataset_pacmobile.push(results.mobile);
          labels_pactual.push(results._id);
        });
      }
      let dataset_padesktop = [];
      let dataset_pamobile = [];
      let labels_panterior = [];
      if (data[1].dataset) {
        data[1].dataset.forEach(function(results) {
          dataset_padesktop.push(results.desktop);
          dataset_pamobile.push(results.mobile);
          labels_panterior.push(results._id);
        });
      }
      let dataset_aadesktop = [];
      let dataset_aamobile = [];
      let labels_aanterior = [];
      if (data[2].dataset) {
        data[2].dataset.forEach(function(results) {
          dataset_aadesktop.push(results.desktop);
          dataset_aamobile.push(results.mobile);
          labels_aanterior.push(results._id);
        });
      }
      new Chart(id, {
        type: 'line',
        data: {
          labels: labels_pactual,
          datasets: [
            {
              label: 'Período actual (Desktop)',
              data: dataset_pacdesktop,
              fill: false,
              borderColor: 'rgb(255, 0, 0)',
              borderWidth: 1,
              hidden:  false,
            },
            {
              label: 'Período anterior (Desktop)',
              data: dataset_padesktop,
              fill: false,
              borderColor: 'rgb(255, 0, 255)',
              borderWidth: 1,
              hidden: false,
            },
            {
              label: 'Año anterior (Desktop)',
              data: dataset_aadesktop,
              fill: false,
              borderColor: 'rgb(0, 0, 255)',
              borderWidth: 1,
              hidden: false,
            },
            {
              label: 'Período actual (Mobile)',
              data: dataset_pacmobile,
              fill: false,
              borderColor: 'rgb(0, 127, 0)',
              borderWidth: 1,
              hidden: false,
            },
            {
              label: 'Período anterior (Mobile)',
              data: dataset_pamobile,
              fill: false,
              borderColor: 'rgb(0, 255, 0)',
              borderWidth: 1,
              hidden: false,
            },
            {
              label: 'Año anterior (Mobile)',
              data: dataset_aamobile,
              fill: false,
              borderColor: 'rgb(0, 255, 255)',
              borderWidth: 1,
              hidden: false,
            },
          ],
        },
        options: {
          title: {
            display: false,
          },
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxWidth: 15,
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  color: 'rgba(0, 0, 0, 0)',
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  display: true,
                },
                gridLines: {
                  color: 'rgba(0, 0, 0, 0)',
                },
              },
            ],
          },
        },
      });
    }
    

export {
  createLineChartType1,
  createLineChartType2
}