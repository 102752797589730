<template>
  <article class="card card--big card--big--medium loading">
    <div class="card__graph font-centered">
      <h3 class="card__title">
        Sin título
      </h3>
      <figure class="card__graph graph_js_output" />
    </div>
    <div class="card__data">
      <div class="card__data__item">
        <p class="card__title font-size-short">
          Sin título
        </p>
      </div>
      <div class="card__data__item">
        <p class="card__title font-size-short">
          Sin título
        </p>
      </div>
      <div class="card__data__item">
        <p class="card__title font-size-short">
          Sin título
        </p>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.loading {
  h3,
  h4,
  p,
  img,
  figure {
    color: #e3e3e3 !important;
    background: #e3e3e3 !important;
    border-radius: 5px !important;
    display: block;
  }
  h3,
  h4 {
    height: 30px;
  }
  figure {
    width: 100%;
    height: 200px;
  }
}
</style>
