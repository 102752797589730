<template lang="html">
  <article
    v-if="!loading && data"
    class="card card--big card--big--medium"
  >
    <div class="card__graph font-centered">
      <h3 class="card__title font-size-tiny text-upper w-bold">
        {{ titleIfData }}
      </h3>
      <figure class="card__graph graph_js_output">
        <canvas :id="api" />
      </figure>
    </div>
  </article>

  <GraficoLineaSkeleton v-else />
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-dupe-keys */
import { onMounted, watch, ref, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
// Libs
import axios from 'axios';
import dataPersistence from '../services/dataPersistence';
import { sleep } from '../utils/index';

// Components
import GraficoLineaSkeleton from '@/components/Skeletons/GraficoLineaSkeleton.vue';

export default {
  components: {
    GraficoLineaSkeleton,
  },
  props: {
    name: String,
    metodo: String,
    title: String,
    source: String,
    api: String,
    dataSource: String,
    chartMethod: Function,
  },
  setup(props) {
    const store = useStore();
    // Props
    const { name, metodo, title, source, api, dataSource } = toRefs(props);

    // States
    const keyStorage = `${title.value.split(' ').join('-')}-${metodo.value}-${
      name.value
    }${api.value}`;
    const URL = `${source.value}/${dataSource.value}/${metodo.value}/${name.value}/`;
    const data = ref(dataPersistence.getItem(keyStorage) || null);
    const loading = ref(false);
    // Computed
    const fechaReporte = computed(() => store.getters.getFechaReporte);
    const titleIfData = computed(() => {
      let emptyDataset = 0;
      if (data.value) {
        data.value.forEach((el) => {
          if (el.dataset == []) {
            emptyDataset++;
          }
        });

        if (data.value.length == emptyDataset) {
          return `${title.value} - Sin datos`;
        }
      }

      return title.value;
    });

    // Methods
    const fetchData = () => {
      axios
        .post(URL, fechaReporte.value)
        .then((response) => {
          data.value = response.data;
          dataPersistence.setItem(keyStorage, response.data);
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // Mounted
    onMounted(() => {
      if (data.value) {
        props.chartMethod(api.value, data.value);
      }

      if (!data.value) {
        loading.value = true;
        fetchData();
      }
    });

    // Watcher's
    watch(fechaReporte, () => {
      loading.value = true;
      fetchData();
    });

    watch(data, async () => {
      // TODO - Mejorar comportamiento del render del Charts
      await sleep(100).then(() => {
        props.chartMethod(api.value, data.value);
      });
    });
    return { data, api, loading, titleIfData };
  },
};
</script>
<style lang="scss" scoped>
.loading {
  h3,
  h4,
  p,
  img,
  figure {
    color: #e3e3e3 !important;
    background: #e3e3e3 !important;
    border-radius: 5px !important;
    display: block;
  }
  h3,
  h4 {
    height: 30px;
  }
  figure {
    width: 100%;
    height: 200px;
  }
}
</style>
