<template>
  <main>
    <section class="horizon">
      <div class="container ">
        <div class="in-between">
          <h2 class="horizon__title">
            Conversiones
          </h2>
        </div>

        <div
          class="row"
          data-equalize="target"
          data-mq="tablet-down"
        >
          <div class="gr-12 card--group">
            <div class="row">
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyhour"
                name="cotizaciones"
                api="postulaciones_por_hora"
                title="Postulaciones por hora"
                :chart-method="createLineChartType1"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyday"
                name="cotizaciones"
                api="postulaciones_por_dia"
                title="Postulaciones por día"
                :chart-method="createLineChartType1"
              />

              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyhour"
                name="contacto"
                api="contactos_por_hora"
                title="Contactos por hora"
                :chart-method="createLineChartType1"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyday"
                name="contacto"
                api="contactos_por_dia"
                title="Contactos por día"
                :chart-method="createLineChartType1"
              />

              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyhour"
                name="clicktocall"
                api="llamados_por_hora"
                title="Llamados por hora"
                :chart-method="createLineChartType1"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyday"
                name="clicktocall"
                api="llamados_por_dia"
                title="Llamados por día"
                :chart-method="createLineChartType1"
              />

              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyhour"
                api="simulaciones_por_hora"
                name="simulaciones"
                title="Simulaciones de beneficios por hora"
                :chart-method="createLineChartType1"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyday"
                api="simulaciones_por_dia"
                name="simulaciones"
                title="Simulaciones de beneficios por día"
                :chart-method="createLineChartType1"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyhour"
                api="simpuntaje_por_hora"
                name="simulacion_puntaje"
                title="Simulaciones de puntaje por hora"
                :chart-method="createLineChartType1"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="countbyday"
                api="simpuntaje_por_dia"
                name="simulacion_puntaje"
                title="Simulaciones de puntaje por día"
                :chart-method="createLineChartType1"
              />

              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="devicebyhour"
                name="cotizaciones"
                api="dispositivos_por_hora"
                title="Postulaciones por dispositivo por hora"
                :chart-method="createLineChartType2"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="devicebyday"
                name="cotizaciones"
                api="dispositivos_por_dia"
                title="Postulaciones por dispositivo por día"
                :chart-method="createLineChartType2"
              />

              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="devicebyhour"
                api="dispositivos_por_hora_simpun"
                name="simulacion_puntaje"
                title="Simulaciones de puntaje por dispositivos por hora"
                :chart-method="createLineChartType2"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="devicebyday"
                api="dispositivos_por_dia_simpun"
                name="simulacion_puntaje"
                title="Simulaciones de puntaje por dispositivos por día"
                :chart-method="createLineChartType2"
              />

              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="devicebyhour"
                api="dispositivos_por_hora_sim"
                name="simulaciones"
                title="Simulaciones de beneficios por dispositivos por hora"
                :chart-method="createLineChartType2"
              />
              <grafico-lineas
                :source="mongo"
                :data-source="dashboardDB"
                metodo="devicebyday"
                api="dispositivos_por_dia_sim"
                name="simulaciones"
                title="Simulaciones de beneficios por dispositivos por día"
                :chart-method="createLineChartType2"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import GraficoLineas from '@/components/GraficoLineas.vue'
// Charts methods
import { createLineChartType1, createLineChartType2 } from '../utils/chartMethods'

export default {
  name: 'Conversiones',
  components: {
    'grafico-lineas': GraficoLineas,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const dashboardDB = computed(() => store.getters.getDBDashboard)
    const analytics = computed(() => store.getters.getAnalyticsURL)
    return { mongo, analytics, dashboardDB, createLineChartType1, createLineChartType2 }
  },
}
</script>

<style lang="css" scoped></style>
